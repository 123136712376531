:root{
    --colorPrimary: #C41D7F;
    --colorPrimaryLight:#FFADD2;
    --colorPrimaryLighter:#FFD6E7;
    --lightPrimary:#FAFAFA;
    --darkPrimary: #141414;
    --darkLightPrimary: #434343;
    --greyPrimary:#8C8C8C;
    --greyLightPrimary:#E1E8ED;
    --greyLightSecondary:#f0f0f0;

    --bgPrimary:#FFFFFF; 
    --bgSecondary: #F5F5F5; /*thème original*/
    
    --greenUp: #00FF00;
    --redDown: #FF0000;
    --greenUpLight: #D9F7BE;
    --redDownLight: #FFD6E7;
    --greenUpLighter: #f6ffed;
    --redDownLighter: #fff0f6;
    --greenUpDark: #237804;
    --redDownDark: #9e1068;

    --lightPrimaryConst:#FAFAFA;
    --darkPrimaryConst: #141414;
    --greyPrimaryConst:#8C8C8C;
}

:root[data-theme='dark']{
    --colorPrimary: #C41D7F;
    --colorPrimaryLight:#FFADD2;
    --colorPrimaryLighter:#FFD6E7;
    --lightPrimary:#141414;
    --darkPrimary: #FAFAFA;
    --darkLightPrimary: #E1E8ED;
    --greyPrimary:#141414;
    --greyLightPrimary:#434343;
    --greyLightSecondary:#434343;

    --bgPrimary:#000000; 
    --bgSecondary: #1f1f1f; /*thème original*/
    
    --greenUp: #00FF00;
    --redDown: #FF0000;
    --greenUpLight: #D9F7BE;
    --redDownLight: #FFD6E7;
    --greenUpLighter: #d9f7be;
    --redDownLighter: #ffccc7;
    --greenUpDark: #237804;
    --redDownDark: #9e1068;

    --lightPrimaryConst:#FAFAFA;
    --darkPrimaryConst: #141414;
    --greyPrimaryConst:#8C8C8C;
}

/*bleu utilisé dans le social chart à enregistrer. bleu twitter utilisé pour l'instant (00acee)*/
/*tous les trucs personnalisés à chaque fois de la même manière alors qu'ils peuvent être personnalisé à la source sur ant- : à changer}*/

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a{
    color: var(--darkPrimary)!important;
}

a:hover{
    color: var(--colorPrimaryLight) !important;
}

a:active{
    color: var(--colorPrimary) !important;
}

a:link {
    text-decoration: none;
    color: var(--darkPrimary);
}


@font-face{
    font-family:'digital-clock-font';
    src: url('./fonts/digital-7/digital-7.ttf');
}

.app{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-anchor: none;
}

.main{
    flex: 1;
    width: 100%;
}

.routes{
    padding: 10px;
    min-height: calc(100vh - 157px);
    background-color: var(--bgPrimary)!important;
}

.footer {
    background-color: var(--darkPrimaryConst);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    height: 55px;
}

.footer-title {
    color: var(--lightPrimaryConst) !important; /*fixe pour ne pas changer selon le thème*/
    font-size: 1rem;
}

.footer-text {
    color: var(--greyPrimaryConst) !important; /*fixe pour ne pas changer selon le thème*/
    font-size: 0.75rem;
    font-weight: 200;
}

.footer-text a{
    color: var(--greyPrimaryConst) !important; /*fixe pour ne pas changer selon le thème*/
    font-size: 0.75rem;
    font-weight: 200;
}

.footer-text :hover {
    color: var(--colorPrimary) !important; /*fixe pour ne pas changer selon le thème*/
    font-size: 0.75rem;
    font-weight: 200;
}

.footer-links {
    color: var(--lightPrimaryConst) !important; /*fixe pour ne pas changer selon le thème*/
}

.loader{
    height: 81vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999!important;
}

/* TickerTape */

.ticker-tape {
    padding-top: 5px;
    padding-top: 5px;
    background-color: var(--lightPrimary);
    border-bottom: 1px solid var(--greyLightPrimary);
}

.ticker-item {
    margin-right: 10px;
    white-space:nowrap;
}

.ticket-tape-logo{
    padding-top: 5px;
}

/* TickerTape end */

/* Navbar */

.navbar {
    padding-bottom: 1px;
    border-bottom: solid 1px var(--greyLightPrimary);
    background-color: var(--bgPrimary);
    box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.12),
      0 6px 16px -11px rgba(0, 0, 0, 0.08);
    position: sticky;
    top: 0;
    margin-bottom: 0px;
    z-index: 999;
  }
  
  .nav-header {
    background-color: var(--bgPrimary);
    border-bottom: 0;
    padding: 0 4px 0 20px;
  }
  
  .logo {
    width: 100px;
    float: left;
  }
  
  .logo a {
    display: inline-block;
    font-size: 20px;
    text-transform: capitalize;
  }
  
  .navbar-menu {
    width: calc(100% - 120px);
    float: left;
  }

  .navbar-menu .ant-layout-header {
    padding: 0 20px;
  }
  
  .navbar-menu .ant-menu-horizontal {
    border-bottom: 0;
    background-color: var(--bgPrimary);
  }
  
  .navbar-menu .ant-menu-item {
    padding-bottom: 0px;
  }
  
  .navbar-menu .leftMenu {
    display: block;
    float: left;  
    width: fit-content;
  }
  
  .navbar-menu .rightMenu {
    float: right;
    display: block;
    width: 75px;
  }

  .navbar-menu .currency {
    float: right;
    margin-right: 10px;
  }

  .navbar-menu .switch-theme {
    float: right;
    margin-right: 10px;
  }

  .navbar-menu .searchMenu {
    display: block;
    width:150px;
    float: right;
    margin-right: 10px;
    margin-top: 17px;
    padding: 0px;
    z-index: 9999;
  }

  .searchMenu-mobile {
    display:none
  }
  
  .menuButton {
    float: right;
    height: 32px;
    margin-top: 17px;
    padding: 6px;
    display: none;
    margin-right: 10px;
  }
  
  .ant-drawer-body {
    padding: 0;
  }
  
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
    display: inline-block;
    width: 100%;
  }
  
  .ant-drawer-body .ant-menu-horizontal {
    border-bottom: none;
  }
  
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom-color: transparent;
  }
  
  .username {
    display: none;
    margin-left: 8px;
  }

.signin-button-div{
    vertical-align: middle;
    float: right;
}

.signin-button{
    color: var(--lightPrimary)!important;
    font-weight: 200;
}

.signin-button:hover{
    color: var(--lightPrimary)!important;
}

.auth-button-submit{
    margin: 10px 0px 10px 0px;
}

.auth-button-new-account{
    float: right;
    margin: 10px 0px 10px 0px;
}

.google-sign-in{
    width:fit-content;
    margin:auto;
}

/* Navbar End */

/* Responsive */

@media screen and (max-width:1600px){
    .fav-button, .like-button {
        width: 70px!important;
    }
}

@media screen and (max-width:1500px){
    .fav-button, .like-button {
        width: 65px!important;
    }
    .social-stats-icon-text {
        font-size: 0.8rem!important;
    }
    .social-stats-name-text {
        font-size: 0.8rem!important;
    }
    .social-stats-value-text {
        font-size: 0.8rem!important;
    }
    .social-stats-change-text {
        font-size: 0.8rem!important;
    }
}

@media screen and (max-width:1400px){
    .fav-button, .like-button {
        width: 60px!important;
    }
    .social-stats-icon-text {
        font-size: 0.7rem!important;
    }
    .social-stats-name-text {
        font-size: 0.7rem!important;
    }
    .social-stats-value-text {
        font-size: 0.7rem!important;
    }
    .social-stats-change-text {
        font-size: 0.7rem!important;
    }
}

@media screen and (max-width:1300px){
    .fav-button, .like-button {
        width: 55px!important;
    }
    .social-stats-icon-text {
        font-size: 0.6rem!important;
    }
    .social-stats-name-text {
        font-size: 0.6rem!important;
    }
    .social-stats-value-text {
        font-size: 0.6rem!important;
    }
    .social-stats-change-text {
        font-size: 0.6rem!important;
    }
}

@media screen and (max-width:1200px){
    .menuButton {
        display: block;
        margin-right: 10px;
    }
    .rightMenu {
        position: absolute;
        left: -9999px;
    }
    .leftMenu {
    position: absolute;
    left: -9999px;
    }
    .logo a {
        margin-left: -10px;
    }
    .ant-drawer-title > .brand-font {
        margin-bottom: 0;
    }
    .username {
        display: inline-block;
    }
    .fav-button, .like-button {
        width: 50px!important;
    }
    .fav-button-mobile, .like-button-mobile {
        width: 70px!important;
    }
    .home-select-coin{
        float:none!important;
    }
    .home-select-time{
        float:none!important;
        margin-top: 10px;
    }
    .home-selector-header-col{
        display: inline-block!important;
        text-align: center!important;
    }
    .crypto-analysis-social-container{
        margin: 10px 0px 10px 0px!important; /*mettre que sur le premier bcp de margin en bas*/
    }
    .crypto-analysis-borders{
        padding: 0px!important;
    }
}

@media screen and (max-width:1000px){
    .main{
        margin-left: 0px;
    }
    .fav-button, .like-button {
        width: 45px!important;
    }
    .fav-button-mobile, .like-button-mobile {
        width: 60px!important;
    }
    .crypto-stats-container, .crypto-technical-container, .crypto-social-container{
        flex-direction: column;
    }
    .crypto-stats-container h2{
        margin-top: 0px;
    }
}

@media screen and (max-width:800px){
    .app{
        flex-direction: column;
        overflow: hidden;
    }
    .fav-button, .like-button {
        width: 40px!important;
    }
    .fav-button-mobile, .like-button-mobile {
        width: 50px!important;
    }
    .main{
        flex: 1;
        margin-top: 0px; /*c'était 90 pourquoi ?*/
        margin-left: 0px;
        margin-right: 10px;
    }
    .ant-menu{
        position: absolute;
        right: 0px;
    }
    .home-title{
        font-size: 1.4rem !important;
    }
    .show-more{
        font-size: 1.3rem !important;
    }
    .home-card-container{
        padding-left: 10px!important;
        padding-right: 10px!important;
    }
    .crypto-list-simplified{
        padding : 25px 10px 0px 10px!important;
    }
    .home-chart{
        padding: 25px 10px 0px 10px!important;
    }
}

@media screen and (max-width:640px){
    .searchMenu {
        display: none!important;
    }
    .searchMenu-mobile {
        display:block;
      }
    .fav-button-mobile, .like-button-mobile {
        width: 100%!important;
    }
    .social-stats-icon-text-mobile, .social-stats-name-text-mobile, .social-stats-value-text-mobile, .social-stats-change-text-mobile {
        font-size: 0.8rem!important;
    }
    .home-card-container{
        padding-left: 5px!important;
        padding-right: 5px!important;
    }
    .crypto-list-simplified{
        padding : 25px 5px 0px 5px!important;
    }
    .home-chart{
        padding: 25px 5px 0px 5px!important;
    }
}

@media screen and (max-width:600px){
    .fav-button-mobile, .like-button-mobile {
        width: 100%!important;
    }
    .social-stats-icon-text-mobile, .social-stats-name-text-mobile, .social-stats-value-text-mobile, .social-stats-change-text-mobile {
        font-size: 0.75rem!important;
    }
}

@media screen and (max-width:575px){
    .fav-button-mobile, .like-button-mobile {
        width: 100%!important;
    }
    .social-stats-icon-text-mobile, .social-stats-name-text-mobile, .social-stats-value-text-mobile, .social-stats-change-text-mobile {
        font-size: 0.9rem!important;
    }
    .social-chart-switch-mobile, .social-timeperiod-mobile, .social-show-price-mobile{
        display: inline-block!important;
    }       
    .crypto-social-selector{
        display:none;
    }
    .technical-ma-switch-mobile, .technical-ma-time-period-mobile, .technical-exp-switch-mobile, .technical-fibo-switch-mobile, .fibonacci-data-selector-mobile, .crypto-technical-item-title-mobile{
        display:inline-block!important;
    }
    .crypto-technical-item-heading{
        display:none
    }
    .fibonacci-data-selector{
        font-size: x-small;
        font-weight: bold;
    }

}

@media screen and (max-width:480px){
    .currency {
        visibility: hidden;
    }
    .crypto-price-font {
        font-size:20px;
    }
    .crypto-changeup, .crypto-changedown {
        font-size: 10px;
    }
    .crypto-name, .crypto-symbol, .crypto-arrow {
        font-size: 16px!important;
    }
}

/* Responsive End */

/* LineChart */

.chart-header{
    display: flex;
    justify-content: space-between;
    gap: 50px;
    color: #0071bd;/*non utilisé*/
}
.chart-title{
    color: #0071bd !important;/*non utilisé*/
}

.price-change{
    font-weight: 900 !important;
}
.current-price{
    margin-top: 0px !important;
    font-weight: 900 !important;
}

/* LineChart End */

/* Cryptocurrencies */

.crypto-card-container{
    min-height: 65vh !important;
    padding : 10px 20px 10px 20px;
    color: var(--greenUpLighter)!important
}
.crypto-card{
    min-width: 250px;
    color: var(--greenUpLighter)!important
}
.crypto-card .crypto-image{
    width:35px;
}

.crypto-card-title-up{
    color: var(--greenUpDark)!important;
}
.crypto-card-title-down{
    color: var(--redDownDark)!important;
}

.crypto-card-up {
    background-color: var(--greenUpLighter);
}

.crypto-card-down {
    background-color: var(--redDownLighter);
}

.crypto-list-simplified{
    padding : 25px 50px 0px 50px;
    width:100vw!important;
}

.crypto-list{
    margin : 10px 0px 0px 0px;
    width: 100vw;
}

.top-coins-table-container{
    text-align: center; /*pas nécessaire*/
}

.list-options-container{
    margin: 15px 0px 0px 0px;
}

.list-options{
    margin:auto
}

.top-coins-table-row{
    height: 100%;
}

.ant-table-tbody{
  padding: 14px !important;
}

.ant-table-tbody > tr > td{
  padding: 14px !important;
}

.pagination-container{
    margin: 10px 0px 0px 0px;
    text-align: center;
}

.pagination{
    margin: 0px 0px 0px 0px;
}

/* Cryptocurrencies End */

/* Portfolio */

.search-portfolio-container{
    text-align: center;
    margin: 5px 0px 0px 0px;
}

.search-portfolio{
    margin: 0px 0px 0px 0px!important;
}

/* Portfolio End */

/*Coinpage*/

.crypto-heading-container {
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    vertical-align: middle;
}

.crypto-heading {
    background-clip: content-box;
    border-color: var(--darkPrimary);
    border-width: 2px;  
    border-style:hidden;
    border-bottom:solid;
    /*border-radius: 25px;*/
}

.crypto-heading-left {
    display:inline;
    /*border-radius: 25px;*/
}

.crypto-like-container, .crypto-fav-container {
    text-align: center;
}

.fav-button, .like-button, .fav-button-mobile, .like-button-mobile {
    font-size: 100%;
    width: 75px;
    height: 30px;
    float: right;
    background-color: var(--bgSecondary);
    border: solid 1px var(--greyLightPrimary);
    color: var(--colorPrimary)!important; /*garder important ?*/
    margin: 5px 0px 5px 0px;
}

.crypto-like-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.crypto-heading-container .crypto-logo {
    margin-top:5px;
    margin-bottom: 5px;
    margin-left:5px;
    width: 30px;
    height: 30px;
}

.crypto-heading-container .crypto-name, .crypto-heading-container .crypto-symbol {
    font-weight: 900;
    font-size:18px;
    text-align: left;
    margin-top:auto;
    margin-bottom: auto;
}

.crypto-heading-container .crypto-name {
    color: var(--darkPrimary);
    margin-left:15px;
}

.crypto-heading-container .crypto-symbol {
    color: var(--greyPrimaryConst);
    margin-left:5px;
}

.crypto-heading-container .crypto-changeup, .crypto-heading-container .crypto-changedown {
    font-weight: 900;
    font-size:14px;
    margin-top:2px;
    margin-bottom: auto;
    margin-left:5px;
    margin-right:5px;
    padding : 0px 5px 0px 5px;
}

.crypto-heading-container .crypto-changeup{
    background-color: var(--greenUp);
    color: var(--lightPrimary);
}

.crypto-heading-container .crypto-changedown {
    background-color: var(--redDown);
    color: var(--lightPrimary);
}

.crypto-heading-container .crypto-arrow {
    font-size:25px;
    margin-left:10px;
}

.crypto-heading-container .crypto-price {
    font-weight: 900;
    font-size:25px;
    color: var(--darkPrimary);
    text-align: center;
    margin:auto;    
    margin-right:15px;
}

.crypto-price-font {
    font-family:'digital-clock-font'!important;
    
}

.crypto-heading-container p{
    font-size: 1rem;
    opacity: 0.9;
}

.chart-options-container{
    margin: 5px auto 5px 0px;
}

.chart-technical-container{
    margin-top: 5px;
    background-color: var(--bgSecondary);
    background-clip:content-box;
}

#technical-title{
    color: var(--darkPrimary);
    margin-left: 5px;
}

#technical-button{
    margin: 5px 0px 5px 0px;
    border: 1px solid var(--greyLightPrimary);
}

#exponential{
    display: flex;
    float: right;
    margin-right: 5px;
}

.chart-timeperiod-container, .chart-interval-container, .chart-type-container, .chart-ma-toogle-container{
    display:true;
    padding:0px!important;
}

.chart-ma-toogle-container{
    text-align: left;
}

.chart-timeperiod, .chart-interval{
    float : left;
}

.chart-type{
    float : right;
    border:1px solid var(--greyLightPrimary);
}

.crypto-analysis-container{
    display: fit-content;
    width: 100%;
    height: 100%;
    padding: 0px!important;
}

.crypto-analysis-borders{
    height: 100%;
}

.crypto-analysis-social-container{
    margin: 0px 0px 10px 0px; /*mettre que sur le premier bcp de margin en bas*/
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
    background-color: var(--bgSecondary);
    border-top:2px solid var(--greyLightPrimary);
    border-bottom:2px solid var(--greyLightPrimary);
}

.crypto-analysis-technical-container{
    margin: 5px 0px 0px 0px; /*mettre que sur le premier bcp de margin en bas*/
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
    background-color: var(--bgSecondary);
    border-top:2px solid var(--greyLightPrimary);
    border-bottom:2px solid var(--greyLightPrimary);
}

.crypto-analysis-heading{
    padding: 2px;
    margin: 0px 15px 10px 15px;
    text-align: center;
    background-color: transparent;
    opacity: 0.85;
    border:5px solid var(--darkLightPrimary);
    box-shadow: 6px 6px 0 var(--greyPrimary);
    background-color: var(--lightPrimary);
}

.crypto-analysis-title{
    font-weight: 800 !important;
    font-size: 1.5rem !important;
    margin-bottom: 0px !important;
    color: var(--darkPrimary) !important;
}

.crypto-technical-item-title, .crypto-social-item-title{
    text-align: center;
    background-clip: padding-box;
}

.crypto-technical-item-heading{
    text-align: center;
}

.crypto-technical-item-container, .crypto-social-item-container{
    margin: 2px auto 2px auto;
}

.crypto-technical-selector, .crypto-social-selector {
    align-content: center;
    text-align: center;
}

.crypto-technical-item, .crypto-social-item {
    min-height:30px;
    margin-bottom: 0px !important;
    display: fit-content;
    align-content: center;
    text-align: center;
    padding: 5px;
    box-shadow: inset 0 0 0 4px var(--bgSecondary), inset 0 0 0 5px var(--greyLightPrimary);
    background-color: var(--bgSecondary);
    border-radius:5%;
}
#moving-average{
    font-weight: 700;
}
#spark-lines{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--greyPrimary);
}
#data-selector{
    border: 1px solid var(--greyLightPrimary);
    border-radius: 5px;
    background-color: var(--bgPrimary);
}

.ant-segmented-item-selected {
    background-color: var(--bgPrimary)!important;
    border: 1px solid var(--colorPrimary)
}

.social-stats, .technical-stats{
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    opacity: 0.9;
    padding: 4px;
    border: 1px solid var(--lightPrimary);
}

.social-stats:hover, .technical-stats:hover{
    background-color: var(--bgSecondary);
}

.social-stats-name, .technical-stats-name {
    display: flex;
    width: 40%;
    gap: 10px;
    font-size: 1rem;
    text-align: left;
}

.social-stats-value, .technical-stats-value{
    width: 35%;
    font-weight: 800;
    text-align: center;
}

.social-stats-change-positive, .technical-stats-change-positive, .social-stats-change-negative, .technical-stats-change-negative, .social-stats-change-neutral, .technical-stats-change-neutral{
    width: 25%;
    font-size: 1rem;
    text-align: right;
    padding-right:5px;
    border-radius: 2px;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
}

.social-stats-change-positive, .technical-stats-change-positive {
    background-color: var(--greenUpLight);
}
#twitter-values-positive{
    border: 0px solid;
}

.social-stats-change-negative, .technical-stats-change-negative {
    background-color: var(--redDownLight);
}
#twitter-values-negative{
    border: 0px solid;
}

.social-stats-change-text, .social-stats-change-text-mobile{
    color: var(--darkPrimaryConst);
}

.social-stats-icon-text, .social-stats-icon-text-mobile {
    font-size: 0.9rem;
}
.social-stats-name-text, .social-stats-name-text-mobile {
    font-size: 0.9rem;
}
.social-stats-value-text, .social-stats-value-text-mobile {
    font-size: 0.9rem;
}
.social-stats-change-text, .social-stats-change-text-mobile {
    font-size: 0.9rem;
}

.crypto-hilights-container{
    width: 100%;
}

.crypto-hilights{
    opacity: 0.9;
    padding-right: 2px;
}

.crypto-hilights-name, .crypto-hilights-value{
    text-align: center;
}

.crypto-hilights-name{
    background-color: var(--darkPrimary);
    font-weight: bold;
    font-size: 1rem;
    border-top-right-radius:5px;
    border-top-left-radius:5px;
}
#hilights-icon {
    color: var(--lightPrimary) ;
}
#hilights-text {
    color: var(--lightPrimary) ;
}

.crypto-hilights-value{
    background-color: var(--bgSecondary);
    border-bottom: 1px solid var(--greyLightPrimary);
    border-left: 1px solid var(--greyLightPrimary);
    border-right: 1px solid var(--greyLightPrimary);
    font-size: 1rem;
    border-bottom-right-radius:5px;
    border-bottom-left-radius:5px;
}

.lunar-stats{
    margin:auto;
    text-align: center;
}
#lunar-social-volume{
    margin: 1px 1px 0px 20px;
    background-color: var(--bgPrimary);
    box-sizing: content-box;
    border: solid 1px var(--greyLightPrimary);
    border-radius: 5px;
}
#lunar-social-dominance{
    margin: 1px 20px 0px 1px;
    background-color: var(--bgPrimary);
    box-sizing: content-box;
    border: solid 1px var(--greyLightPrimary);
    border-radius: 5px;
}

.ant-tabs-nav{
    margin-bottom: 12px!important; /*essayé de mettre dans config provider sous Tabs mais ça marche pas, à réessayer à la mise à jour de antd. c'est plus propre*/
}

.social-chart-switch-mobile{
    display: none;
}

.social-timeperiod-mobile{
    display: none;
}

.social-show-price-mobile{
    display: none;
}

.technical-ma-switch-mobile{
    display:none;
}
.technical-ma-time-period-mobile{
    display:none;
}
.crypto-technical-item-title-mobile{
    display: none;
}
.technical-exp-switch-mobile{
    display:none;
}
.technical-fibo-switch-mobile{
    display:none;
}
.fibonacci-data-selector-mobile{
    display:none;
}

.copyright-lunarcrush{
    margin: auto;
    text-align: center;
}

/*il faut filtrer crypto Anas : priiincipalement coinpage + un peu de topcoins whatif etc*/

/*Whatif*/

.what-if-hilights{
    opacity: 0.9;
    padding-right: 2px;
}

.what-if-hilights-name, .what-if-hilights-value {
    text-align: center;
}

.what-if-hilights-name{
    background-color: var(--darkPrimary);
    font-weight: bold;
    font-size: 1rem;
    border-top-right-radius:5px;
    border-top-left-radius:5px;
}
#what-if-icon {
    color: var(--lightPrimary) ;
}
#what-if-text {
    color: var(--lightPrimary) ;
}

.what-if-hilights-value {
    background-color: var(--bgSecondary);
    border-bottom: 1px solid var(--greyLightPrimary);
    border-left: 1px solid var(--greyLightPrimary);
    border-right: 1px solid var(--greyLightPrimary);
    font-size: 1rem;
    border-bottom-right-radius:5px;
    border-bottom-left-radius:5px;
}

.what-if-container{
    margin: 10px auto 10px auto;
    display: flex;
}

.what-if-hilights-value{
    height: 30px;
}

.what-if-input, .what-if-date{
    height: 100%;
    margin: auto;
    border: none;
}

/*Whatif end*/

/*Topcoins*/

.top-coins-table-container, .fav-coins-table-container{
    padding: 10px;
}

.top-coins-table, .fav-coins-table{
    margin:auto;
}

.top-coins-periods{
    margin:auto;
}

/*Topcoins end*/

/*HomePage*/

.home-global-stats-container{
    display: flex;
}

.home-global-stats{
    margin: auto;
    text-align: center!important;
}

.home-global-stats-text{
    font-family:'digital-clock-font'!important;
    font-size: 1rem;
}

.home-global-stats-value{
    font-family:'digital-clock-font'!important;
    color: var(--colorPrimary);
    font-size: 1rem;
}

.treemap-img{
    width: 100%;
    height: 225px;
    border: 1px solid var(--greyLightPrimary);
    border-radius: 10px;
    background-color: var(--bgPrimary);
    box-shadow: 3px 3px 0 var(--bgSecondary);
}

.home-selector-header{
    margin-top: 5px;
    margin-bottom: 5px;
}

.home-select-coin{
    float: left;
}

.home-select-time{
    float: right;
}

.home-card-container{
    padding-left: 50px;
    padding-right: 50px;
}

.home-card{
    padding: 0px!important;
    margin: 5px 0px 5px 0px;
    height : 225px; /*à supprimer*/
    border: 1px solid var(--greyLightPrimary);
    border-radius: 10px;
    background-color: var(--lightPrimary);
    box-shadow: 3px 3px 0 var(--bgSecondary);
}

.home-card-carousel{
    height: 215px;
    background-color: var(--lightPrimary);
    border-radius: 10px;
}

.ant-carousel .slick-dots li button {
    background: var(--colorPrimary);
    opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
    background: var(--colorPrimary);
    background: #ff4ef6;
}

.home-card-heading{
    padding: 10px 30px 0px 30px;
    text-align: center;
    background-color: transparent;
    opacity: 0.85;
    border-radius: 5px;
}

.home-card-title{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600 !important;
    font-size: 1rem !important;
    margin-bottom: 0px !important;
    color: var(--darkLightPrimary) !important;
}

.home-best-coins-logo{
    height: 25px;
    width: 25px;
    border-radius: 10px ;
}

.home-best-coins-table {
    padding-top: 15px;
    .ant-table-tbody > tr > td{
        padding: 10px !important;
    };
}

.home-best-coins-more {
    margin-top: 5px;
    text-align: center;
}

.home-best-coins-more-text {
    font-weight: 700;
    color: var(--colorPrimary);
}

.home-chart{
    padding: 25px 50px 0px 50px;
}

.home-coins-list-more{
    text-align: center;
    padding: 5px 0px 0px 0px;
    font-weight: 700;
    color: var(--colorPrimary);
}

/*HomePage End*/

/*Profil*/
.profil-container{
    padding: 20px 5px 10px 5px;
}

.profil-password{
    padding: 10px 5px 0px 5px;
}

/*Profil End*/

/*Auth*/

.auth-container{
    padding-top: calc(100vh/6); 
    width: 100%;
    text-align: center;
}

.auth-input-control{
    margin-bottom: 10px;
}

.auth-file-input-control{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.auth-button-submit{
    margin: 10px;
}

.google-auth-button-submit{
    margin: 10px;
    width: 100%;
    text-align: center;
}

.auth-button-clear{
    margin: 10px;
}

.google-sign-in{
    display: flex;
    align-items: center;
    justify-content: center;
}

/*Auth end*/

/*Treemap*/

#treeMapChart {
    background: #212121;
    position: fixed; /*relative ?*/
    height:inherit; /*peaufiner*/
    left: 0px;
    right: 0px;
    top: 50px;
    bottom: 0px;
    font-family: 'Vazir', sans-serif; /*modifier*/
}
  
g text.shadow{
    text-shadow: 0.025em 0.04em 0 #c41d7f;

}

g {
    cursor: grab;
    fill: #fff;
    fill-opacity: 1;
}

rect{
    pointer-events: all; /*ou fill ?*/ 
}

rect:hover {
    opacity: 0.8!important;
}

text{
    z-index:1;
    pointer-events: none;
}

#toolTipTxt {
    fill : white;
    font-size : 18;
    font-weight : 400
}

.d3-tip {
    line-height: 1;
    padding: 6px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 4px;
    border: 1px;
    pointer-events: none !important;
    outline: 1px solid #c41d7f;
}

/* Creates a small triangle extender for the tooltip 
.d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\25BC";
    position: absolute;
    text-align: center;
}
*/

/*Treemap end*/

/*EDITS ANAS : les caser quelquepart si encore utilisés*/

.collapse-header {
    flex: auto;
}

.legend {
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
}

.ant-table {
    scrollbar-color: var(--greyPrimary) var(--greyLightPrimary)!important;
    /* creates padding around scroll thumb */
}